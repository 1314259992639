
import Vue from 'vue'
import { Control } from '@/code/project';

  export default Vue.extend({
    name: 'Slider',

    props: {
      control: Control
    },
  })
